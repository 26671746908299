<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Programmazione attività</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.params.id }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <div v-if="inserisci==false">
        <ion-button v-model="inserisci" @click="apriInserimento">INSERISCI PROGRAMMAZIONE</ion-button>
        </div>
        <div v-if="inserisci==true">
          <ion-label>Data termine:</ion-label>
          <ion-item>
            <ion-datetime :min="minDate" :max="maxDate" v-model="calendar.date"></ion-datetime>
          </ion-item>
          <br>
          <ion-label>Riferimento Offerta</ion-label>
          <ion-item>
            <ion-select placeholder="Inserire il riferimento offerta"  v-model="calendar.ref_off">
              <ion-select-option :value="o.ref_off" v-for="o,i in contracts" :key="'contracts-'+i">{{o.ref_off}}</ion-select-option>    
            </ion-select>
          </ion-item>
          <br>
          <ion-label>Task</ion-label>
          <ion-item>
            <ion-select placeholder="Inserire il riferimento offerta" v-model="calendar.task" @click="getRefOffTasks">
              <ion-select-option :value="t.title" v-for="t,i in tasks" :key="'tasks-'+i">{{t.title}} ({{t.company}})</ion-select-option> 
            </ion-select>
          </ion-item>
          <br>
          <div v-if="$store.state.userRole>4">
            <ion-label>Seleziona l'utente</ion-label>
            <ion-item>
              <ion-select placeholder="Inserire l'utente associato" v-model="calendar.user">
                <ion-select-option :value="c.name" v-for="c,i in users" :key="'consultant-'+i">{{c.name}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
          <ion-button @click="newCalendar" color="success">Inserisci</ion-button>
        </div>
        <h2>La mia programmazione mensile:</h2>
        <dtable          
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="calendarHeading"
          :data="calendars"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getCalendars"
        >
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton,    
    IonButtons, 
    IonContent,
    IonDatetime, 
    IonHeader,
    IonItem,
    IonLabel, 
    IonMenuButton, 
    IonPage, 
    IonSelect,
    IonSelectOption,
    IonTitle, 
    IonToolbar,
    toastController
  } from '@ionic/vue';
  import Dtable from './partials/Dtable';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonContent,
      IonDatetime,
      IonHeader,
      IonItem,
      IonLabel,
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar,
      Dtable
    },
    data:function(){
      return{
        calendarHeading: [
          {
            text: 'Data',
            code: 'date'
          },
          {
            text: 'Rif. Offerta',
            code: 'ref_off'
          },
          {
            text: 'Task',
            code: 'task'
         },         
        ],
        inserisci:'',
        users:[],
        contracts:[],
        tasks:[],
        calendar:{
            date:'',
            ref_off:'',
            task:'',
            user:''
        },
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'date',
          order: 'asc',
        },
        calendars:[],
        mesi:['01','02','03','04','05','06','07','08','09','10','11','12'],
        mesereq:'',
        minDate:'',
        maxDate:'',
      }
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-users-list', {}, this.headers)
        .then(async (response) => {
          this.users = response.data.users
        })
      },
      getContracts(){
        this.axios.post(this.apiUrl + 'get-contracts-notable', { }, this.headers)
        .then(async (response) => {
          this.contracts = response.data.contracts
        })
      },
      getTasks(){
        this.axios.post(this.apiUrl + 'get-tasks', {}, this.headers)
        .then(async (response) => {
          this.tasks=response.data.tasks;
        })
      },
      apriInserimento(){
          this.inserisci=!this.inserisci;
      },
      getCalendars(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
      this.axios.post(this.apiUrl + 'get-calendars', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
      .then(async (response) => {
        if(response.data.status_code==200){
          this.calendars=response.data.calendars.data
          this.table.total = response.data.calendars.total
          this.table.perPage = response.data.calendars.per_page        
        }
      })
    },
    getMonthCalendars(){
      this.flagmesecur=1;
      this.axios.post(this.apiUrl + 'get-calendars', {calendar:this.calendar, mesereq:this.mesereq}, this.headers)
      this.getCalendars();
    },
    getRefOffTasks(){
      this.axios.post(this.apiUrl + 'get-refoff-task', {ref_off:this.calendar.ref_off}, this.headers)
      .then(async (response) => {
          this.tasks = response.data.tasks;
      })
    },
    async newCalendar(){
      this.axios.post(this.apiUrl + 'save-calendar', {calendar:this.calendar}, this.headers)
      .then(async (response) => {
        if(response.data.status_code==200){
            this.calendar.date='';
            this.calendar.user='';
            this.calendar.task='';
            this.calendar.ref_off='';
      
            const toast = await toastController
            .create({
              color: 'success',
              message:'Nuovo appuntamento inserito',
              duration: 2000
          })
          return toast.present();
        }
        //console.log(response)
      })
    },
    getUserInfo(){
      this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
      .then(async (response) => {
        this.minDate=response.data.now;
        this.maxDate=response.data.fiveYear;
      })
    },
  },
  ionViewWillEnter: function(){
   this.getUser();
   this.getContracts();
   this.getCalendars();
   this.getUserInfo();
   //this.getRefOffTasks();
  }
}
</script>

<style scoped>
  #container{
    width:90%;
    margin:3%; 
    margin-right:2%;
  }
  #mesev{
    border:1px solid grey;
    width:5%;
    margin:10px;
    display:block;
  }
  @media only screen and (max-width: 600px) {
    h2{
      font-size:18px;
    }
  }
</style>